import { Injectable, Injector } from '@angular/core';
import axios, { AxiosInstance, AxiosError } from 'axios';
import { Observable, throwError } from 'rxjs';
import * as Global from 'src/app/global';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {
  public apibaseUrl = Global?.API_URL;
  private axiosInstance: AxiosInstance;
  
  constructor(private injector: Injector) {
    let spinner = this.injector.get(NgxSpinnerService);
    this.axiosInstance = axios.create({
      baseURL: this.apibaseUrl, // Replace with your API URL
      //timeout: 5000, // Adjust as needed
    });

    // Add a global error handler
    spinner.show();

    this.axiosInstance.interceptors.response.use((response) => {
      spinner.hide();
      return response;
  }, (error: AxiosError) => {
    spinner.hide();
    if (error.response) {
      // The request was made, but the server responded with an error status code
      console.error('Response Error:', error.response.data);
    } else if (error.request) {
      // The request was made, but no response was received
      console.error('Request Error:', error.request);
    } else {
      // Something happened while setting up the request
      console.error('Error:', error.message);
    }
    return throwError(error); // Pass the error to the caller
  });



  }

  // Get post method etc
  get(url: string) {
    return this.axiosInstance.get(url);
  }

  post(url: string, data: any) {
    return this.axiosInstance.post(url, data);
  }

}
