<footer>
  <app-contact-form></app-contact-form>
  <div class="contact-bottom overflow-hidden">
    <div class="container">
      <div class="c-details">
        <ul>
          <li>
            <span><i class="fa fa-phone" aria-hidden="true"></i></span>
            <a href="tel:+971-564427403">+(971) 564427403</a> / <a href="tel:+971-042396311">042396311</a>
          </li>
          <li>
            <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
            <a href="mailto:info@infotreeit.com">info@infotreeit.com</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="contact-middle">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 mb-4 mb-lg-0">
            <a routerLink="/"><img src="assets/images/logo.png" width="168" alt="" /></a>
            <ul class="f-social">
              <li>
                <a [href]="settingsDetails?.facebook_url" target="_blank"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a [href]="settingsDetails?.linkedin_url" target="_blank"
                  ><i class="fa fa-linkedin" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a [href]="settingsDetails?.twitter_url" target="_blank"
                  ><img src="assets/images/twitter.png" width="14" alt=""/></a>
              </li>
              <li>
                <a [href]="settingsDetails?.insta_url" target="_blank"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 mb-md-0 mb-4">
            <h4>QUICK LINKS</h4>
            <ul class="ul-list">
              <li><a routerLink="/about">About </a></li>
              <li><a routerLink="/careers">Career </a></li>
              <li><a routerLink="/blogs">Blogs </a></li>
              <li><a routerLink="/contact">Contact Us</a></li>
              <!-- <li><a routerLink="\">Privecy Policy</a></li> -->
            </ul>
          </div>
          <div class="col-lg-6 col-md-9">
            <h4>SERVICES</h4>
            <ul class="ul-list ul-count">
              <li><a routerLink="/quickbooks">QuickBooks</a></li>
              <li><a routerLink="/sage-50">Sage</a></li>
              <li><a routerLink="/xero">Xero</a></li>
              <li><a routerLink="/miclient">MiClient</a></li>
              <li><a routerLink="/tally-erp-9">Tally ERP 9</a></li>
              <li><a routerLink="/quickbooks-add-on">QuickBooks Add-On </a></li>
              <li><a routerLink="/corporate-tax">Corporate Tax </a></li>
              <li><a routerLink="/cyber-security">Cyber Security </a></li>
              <li><a routerLink="/website-development-seo">Website Development & SEO </a></li>
              <li><a routerLink="/vat-calculator">VAT Calculator</a></li>
              <li><a routerLink="/g-suite">G-Suite </a></li>
              <li><a routerLink="/it-amc">IT AMC </a></li>
              <li><a routerLink="/keyprofit">Key Profit </a></li>
              <li><a routerLink="/email-hosting">Email Hosting </a></li>
              <li><a routerLink="/microsoft-office365">Microsoft Office 365</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright text-center">
      Copyright © {{Global.COPYRIGHT_YEAR}} All right reserved l Design and Development By
      <a href="https://infotreeit.com/" target="_blank">Infotreeit</a><a href="https://codecalibre.com" target="_blank" style="display: none;">CODE CALIBRE</a>
    </div>
  </div>
  
  <div class="whatsappsec">
    <a href="https://api.whatsapp.com/send/?phone=971564427403&text&type=phone_number&app_absent=0" target="_blank"><img src="assets/images/whatsapp.png" alt=""></a>
  </div>
</footer>
