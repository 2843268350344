import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { ModulesList } from './menu';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  settingsDetails: any = {};
  
  sampleData: string = '';
  isNavbarFixed: boolean = false;
  mainService: any;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | any;

  modulesList: any;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger:any;
  isMobile: boolean = false;

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 100) {
      this.isNavbarFixed = true;
    } else {
      this.isNavbarFixed = false;
    }
  }

  getLessData() {
    this.sampleData = this.mainService.showLessData();
  }
  getMoreData() {
    this.sampleData = this.mainService.showMoreData();
  }

  constructor(
    private commonHttpService: CommonHttpService,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) { 

    this.modulesList = ModulesList;
  }

  ngOnInit(): void {
    this.fetchGlobalSettings();
  }


  fetchGlobalSettings(){
    return new Promise((resolve) => {
      this.commonHttpService.post('global-settings/list', {}).then((res: any) => {
        const data = res?.data ?? null;
        if (data?.status == 'success') {
          this.settingsDetails = data?.data?.global_settings;
          resolve(true);
        } else {
          this.toastr.error(data?.message);
          resolve(false);
        }
      }).catch((err: any) => {
        this.toastr.error(err);
        resolve(false);
      })
    })
  }
  openMyMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.openMenu();
} 

closeMyMenu() {
  this.trigger.closeMenu();
  console.log("CLLL");
}  

menuenter() {
  this.isMatMenuOpen = true;
  if (this.isMatMenu2Open) {
    this.isMatMenu2Open = true;
  }
}

menuLeave(trigger:any, button:any) {
  setTimeout(() => {
    if (!this.isMatMenu2Open && !this.enteredButton) {
      this.isMatMenuOpen = false;
      trigger.closeMenu();
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    } else {
      this.isMatMenuOpen = false;
    }
  }, 80)
}

menu2enter() {
  this.isMatMenu2Open = true;
}

menu2Leave(trigger1:any, trigger2:any, button:any) {
  setTimeout(() => {
    if (this.isMatMenu2Open) {
      trigger1.closeMenu();
      this.isMatMenuOpen = false;
      this.isMatMenu2Open = false;
      this.enteredButton = false;
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    } else {
      this.isMatMenu2Open = false;
      trigger2.closeMenu();
    }
  }, 100)
}

buttonEnter(trigger:any) {
  setTimeout(() => {
    if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
      this.prevButtonTrigger.closeMenu();
      this.prevButtonTrigger = trigger;
      this.isMatMenuOpen = false;
      this.isMatMenu2Open = false;
      trigger.openMenu();
      // this.renderer.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
      // this.renderer.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
    }
    else if (!this.isMatMenuOpen) {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger
      trigger.openMenu();
      //this.renderer.removeClass(trigger?.menu?.items?.first['_elementRef'].nativeElement, 'cdk-focused');
      //this.renderer.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
    }
    else {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger
    }
  })
}

buttonLeave(trigger:any, button:any) {
  setTimeout(() => {
    if (this.enteredButton && !this.isMatMenuOpen) {
      trigger.closeMenu();
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    } if (!this.isMatMenuOpen) {
      trigger.closeMenu();
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
      this.renderer.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
    } else {
      this.enteredButton = false;
    }
  }, 100)
}
onClickBdToggle(event: any) {
  $(function() {
      //$('.navbar-toggler').click(function() {
      $('.navbar-collapse').toggleClass('show'); 
    //});   
  });
}
  
}
