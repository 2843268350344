import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Global from './global';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  Global = Global;
  PageMainTitle = Global.APP_NAME;
  title = 'infotree-html';
  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private metaService: Meta,
    //private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    //this.ngxZendeskWebwidgetService.initZendesk();
  }
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const rt = this.getActivatedRouteChild(this.activatedRoute);
        rt.data.subscribe((data: any) => {
          if (data.pageTitle) {
            this.titleService.setTitle(data.pageTitle);
          } else {
            this.titleService.setTitle(this.PageMainTitle);
          }
          // Set meta description
          if (data.metaDescription) {
            this.metaService.updateTag({ name: 'description', content: data.metaDescription });
          } else {
            this.metaService.updateTag({ name: 'description', content: 'Empower your business with expert QuickBooks solutions from a trusted QuickBooks Solution Provider. Quickbooks online login & optimize financial management with tailored QuickBooks bookkeeping.' });
          }
          // Set meta keywords
          if (data.metaKeyword) {
            this.metaService.updateTag({ name: 'keywords', content: data.metaKeyword });
          } else {
            this.metaService.updateTag({ name: 'keywords', content: 'QuickBooks Solution Provider, Best Financial Reporting Software, Financial reporting software for small business, free quickbooks online, QuickBooks login, quickbooks online login, QuickBooks download free, intuit quickbooks online, quickbooks bookkeeping, quickbooks online essentials' });
          }
          this.metaService.updateTag({ name: 'robots', content: 'index, follow' });
        });
      }
    });
  }
  getActivatedRouteChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getActivatedRouteChild(activatedRoute?.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
