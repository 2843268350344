
<header [ngClass]="isNavbarFixed?'fixed':'notfixed'" (scroll) = "onScroll()">

  <div class="headtop">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="infoblock">
            <ul>
              <li>
                <span><i class="fa fa-phone" aria-hidden="true"></i></span>
                <a href="tel:+971-564427403">+(971) 564427403</a> / <a href="tel:+971-042396311">042396311</a>
              </li>
              <li>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
                <a href="mailto:info@infotreeit.com">info@infotreeit.com</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="infoso">
            <ul class="f-social textright">
              <li>
                <a [href]="settingsDetails?.facebook_url" target="_blank"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a [href]="settingsDetails?.linkedin_url" target="_blank"
                  ><i class="fa fa-linkedin" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a [href]="settingsDetails?.twitter_url" target="_blank"
                  ><i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a [href]="settingsDetails?.insta_url" target="_blank"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <nav class="navbar navbar-expand-lg" >
      <div class="container position-relative">
        <a class="navbar-brand" routerLink="/"><img src="assets/images/logo.png" width="110" alt=""/></a>
        <button class="navbar-toggler bd_toggle" (click)="onClickBdToggle($event)" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mob_nav">
          <ul class="navbar-nav ms-auto topmenu">
          <ng-container *ngFor="let menuItem of modulesList">            
            
            <ng-container  *ngIf="menuItem.parent">
              <li>
                <a class="nav-link" [routerLink]="menuItem.link" (click)="onClickBdToggle($event)" >
                  <span class="icon fa" [ngClass]="menuItem.icon"></span>
                  <span class="text-holder">{{menuItem.label}}</span>
                </a>
              </li>              
            </ng-container>
            <ng-container *ngIf="menuItem.children.length > 0 " >
              <li>
                <button #button mat-button [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" style="z-index:1050;">
                  <!-- <span class="icon fa" [ngClass]="menuItem.icon"></span> -->
                  <span [routerLink]="menuItem.link">{{menuItem.label}}
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </button>
                
                <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
                  <ul class="dropdownmenu">
                  <ng-container *ngFor="let childL1 of menuItem.children">
                    
                    <li *ngIf="!childL1.children" mat-menu-item>
                      <a class="nav-link" [routerLink]="childL1.link" (click)="onClickBdToggle($event)" >{{childL1.label}}
                        <i *ngIf="childL1.icon" [ngClass]="childL1.icon"></i>
                      </a>
                    </li>
                    <ng-container *ngIf="childL1.children && childL1.children.length > 0">
                      <li mat-menu-item #levelTwoTrigger="matMenuTrigger" [matMenuTriggerFor]="levelTwo">
                        <span class="icon fa" [ngClass]="childL1.icon"></span>
                        <span  [routerLink]="childL1.link">{{childL1.label}}</span>
                      </li>          
                      <mat-menu #levelTwo="matMenu">
                        <ul class="dropdownmenu">
                        <ng-container *ngFor="let childL2 of childL1.children">
                          <li mat-menu-item>
                            <a class="nav-link" [routerLink]="childL2.link" (click)="onClickBdToggle($event)" >{{childL2.label}}
                              <i *ngIf="childL2.icon" [ngClass]="childL2.icon"></i>
                            </a>
                          </li>
                        </ng-container>
                        </ul>
                      </mat-menu>
                    </ng-container>
                    
                  </ng-container>

                  </ul>
                </mat-menu>
              </li>            
          
              
            </ng-container>            

          </ng-container>
          </ul>
        </div>
        
      </div>
    </nav>   
    
</header>
 

