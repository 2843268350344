<div class="contact-block pad70">
    <div class="container">
      <div class="tes-title text-center">
        <span class="sub-title">CONTACT US</span>
        <h2>Get In Touch With Us</h2>
      </div>
  
      <div class="form-block">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div class="contact-form">
              <form [formGroup]="contactForm" (ngSubmit)="contactformSubmit($event)">
                <div class="contact-txt">
                  <h3>Get In Touch</h3>
                  <p>
                    Leave us a note and we will get back to you for a free
                    consultation
                  </p>
                </div>
    
                <div class="row">
                  <div class="col-lg-6 mb-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name*"
                      formControlName="your_name"
                      (keypress)="Global.noSpecialCharactersNumbers($event)"
                      (paste)="(false)"
                      [class.is-invalid]="!Global.isInputValid(contactForm, 'your_name')"
                    />
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_name', 'required')">
                      <div>Please enter your name.</div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-4">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Your Email*"
                      formControlName="your_email"
                      (paste)="(false)"
                      [class.is-invalid]="!Global.isInputValid(contactForm, 'your_email')"
                    /> 
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_email', 'required')">
                      <div>Please enter your email.</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="!Global.isEmailValid(contactForm, 'your_email')">
                      <div>Please enter valid email.</div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-4">
                    <ngx-intl-tel-input
                    [cssClass]="'form-control'"
                    [preferredCountries]="preferredCountries"
                    [customPlaceholder]="'Phone No *'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedArabEmirates"
                    [maxLength]="Global.PhoneMaxLength"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National"
                    name="phone"
                    formControlName="phone"
                    [class.is-invalid]="!Global.isInputValid(contactForm, 'your_mobile')"
                  >
                    </ngx-intl-tel-input>


                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_mobile', 'required')">
                      <div>Please enter your phone number.</div>
                    </div>
                    <!-- <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_mobile', 'pattern')">
                      <div>Please enter valid phone number.</div>
                    </div> -->
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_mobile', 'minlength')">
                      <div>Your Phone should be minimum {{Global.PhoneMinLength}} characters</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_mobile', 'maxlength')">
                      <div>Your Phone should be maximum {{Global.PhoneMaxLength}} characters</div>
                    </div>
                   
                  </div>
                  <div class="col-lg-6 mb-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject*"
                      formControlName="your_subject"
                      (keypress)="Global.noSpecialCharactersNumbers($event)"
                      (paste)="(false)"
                      [class.is-invalid]="!Global.isInputValid(contactForm, 'your_subject')"
                    />
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_subject', 'required')">
                      <div>Please enter subject.</div>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-4">
                    <textarea
                      class="form-control"
                      placeholder="Your Message"
                      formControlName="your_comments"
                      (keypress)="Global.noSpecialCharacters($event)"
                      (paste)="(false)"
                      [class.is-invalid]="!Global.isInputValid(contactForm, 'your_comments')"
                    ></textarea>
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_comments', 'required')">
                      <div>Please enter comments.</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_comments', 'minlength')">
                      <div>Your Comments should be minimum {{Global.MessegeMinLength}} characters</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="!Global.isInputRuleValid(contactForm, 'your_comments', 'maxlength')">
                      <div>Your Comments should be maximum {{Global.MessegeMaxLength}} characters</div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" class="sub-btn">Submit Now</button>
                  </div>
                </div>
              </form>  
              </div>
            
          </div>
          <div class="col-lg-4">
            <img src="assets/images/c-img.png" alt="" class="c-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
