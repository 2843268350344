import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { Page404Component } from './errors/page404/page404.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    loadChildren: () => import('./main-layout/main-layout.module').then((m) => m.MainLayoutModule),
  },
  { path: '**', pathMatch: 'full', component: Page404Component, data: { pageTitle: '404 Not Found' }}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
