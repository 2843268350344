import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FooterComponent } from './includes/footer/footer.component';
import { TeamsComponent } from './components/teams/teams.component';
import { ClientsComponent } from './components/clients/clients.component';
import { HeaderComponent } from './includes/header/header.component';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Page404Component } from './errors/page404/page404.component';
import {
  NgxZendeskWebwidgetConfig,
  NgxZendeskWebwidgetModule,
} from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = true;
  accountUrl = 'infotreecomputersllchelp.zendesk.com';
  zendeskKey = '56a83401-8ef2-43c8-b86b-2f6f8c528236';
  callback(zE: any) {}
}
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    TeamsComponent,
    ClientsComponent,
    ContactFormComponent,
    Page404Component,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    RouterModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    RouterModule,
    MatMenuModule,
    MatButtonModule,NgxIntlTelInputModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
