'use strict';

import { FormGroup } from "@angular/forms";

export const API_URL = 'https://api.infotreeit.com/backend/api';
// export const API_URL = 'https://dev8.ivantechnology.in/inforee_it_new/backend/api';
// export const API_URL = 'https://www.whitejasminedxb.com/backend/api';
export const APP_NAME = 'Infotree It';
export const MessegeMinLength = 5;
export const MessegeMaxLength = 200;
export const PhoneMinLength = 6;
export const PhoneMaxLength = 15;
export const COPYRIGHT_YEAR: any = new Date().getFullYear();

export function getServerErrorMessage(err: any) {
    if (err.status == 401) {
        return err?.error?.message ?? "Unauthorized Action";
    } else if (err.status == 404) {
        return err?.message ?? "Not found exception occured";
    } else {
        return "Internal server error occured. Please try again later";
    }
}
export function getValidationMessage(result: any[]) {
    for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
            const element = result[key];
            if (element.message) {
                return element.message;
            }
        }
    }

    return "Validation Error: Please check all the fields correctly";
}
export function scrollToQuery(query: any) {
    let $_errFormControl = document.querySelectorAll(query);
    if ($_errFormControl.length > 0) {
        const firstErr: Element = $_errFormControl[0];
        firstErr.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}
export function isInputValid(formGroup: FormGroup, control: any) {
    let valid: boolean = true;
    if (!['VALID', 'DISABLED'].includes(formGroup.controls[control].status) && (formGroup.controls[control].touched || formGroup.controls[control].dirty)) {
        valid = false;
    }
    return valid;
}
export function isInputRuleValid(formGroup: FormGroup, control: any, rule: any) {
    let valid: boolean = true;
    if (rule instanceof Array) {
        rule.forEach((r) => {
            if (formGroup.controls[control].hasError(r) && (formGroup.controls[control].touched || formGroup.controls[control].dirty)) {
                valid = false;
            }
        });
    } else {
        if (formGroup.controls[control].hasError(rule) && (formGroup.controls[control].touched || formGroup.controls[control].dirty)) {
            valid = false;
        }
    }
    return valid;
}
export function numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
export function resetForm(formGroup: FormGroup) {
    formGroup.reset();
    for (const key in formGroup.controls) {
        if (Object.prototype.hasOwnProperty.call(formGroup.controls, key)) {
            const element = formGroup.controls[key];
            element.markAsUntouched();
            element.markAsPristine();
        }
    }
}
export function getCurrentLocation(location: any) {
    const parsedUrl = new URL(location);
    const fullUrl = parsedUrl.href;
    return fullUrl;
} 
export function removeTags(str: any) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/(<([^>]+)>)/ig, '');
    }
}
export function noSpecialCharacters(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
}
export function onlyAlphabets(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
        return true;
    }
    return false;
}
export function noSpecialCharactersNumbers(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
}

export function isEmailValid(formGroup: FormGroup, control: any): boolean {
    const emailControl = formGroup.controls[control];
    const emailValue: string = emailControl.value || '';
  
    if (emailValue === '') {
      return true; // Empty email
    }
  
    if (emailControl.hasError('email') || emailControl.hasError('pattern') && (emailControl.touched || emailControl.dirty)) {
      return false; // Invalid email format
    }
    return true; // Valid email
  }
