import { Component, OnInit } from '@angular/core';
import * as Global from 'src/app/global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHttpService } from 'src/app/services/common-http.service';
import { CommonHttpWithTokenService } from 'src/app/services/common-http-with-token.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
declare const gtag: Function;
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  Global = Global;
  contactForm: any = FormGroup;
  isSubmitted:Boolean = false;
  isLoading:Boolean = false;
  countries: any = [];
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private commonHttpService: CommonHttpService,
    private commonHttpWithService: CommonHttpWithTokenService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    this.contactForm = this.formBuilder.group({
      your_name: [null, Validators.compose([Validators.required])],
      your_email: [null,Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
      phone: [null],
      your_mobile: [null, Validators.compose([Validators.required,Validators.minLength(Global.PhoneMinLength), Validators.maxLength(Global.PhoneMaxLength)])],
      your_country_code: ['91', Validators.compose([Validators.required])],
      your_subject: [null, Validators.compose([Validators.required])],
      your_comments: [null, Validators.compose([Validators.minLength(Global.MessegeMinLength), Validators.maxLength(Global.MessegeMaxLength)])],
      page_title: [null, Validators.compose([Validators.required])],
      page_url: [null, Validators.compose([Validators.required])]
    });

  }

  ngOnInit(): void {
    this.fetchCountries();  
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        // Handle NavigationStart event
        this.resetForm();
       
      }
  
      if (ev instanceof NavigationEnd) {
        // Handle NavigationEnd event
        this.resetForm();
      }
  
      if (ev instanceof NavigationCancel) {
        // Handle NavigationCancel event
      }
  
      if (ev instanceof NavigationError) {
        // Handle NavigationError event
      }
    });  
      //set null on phpone
    this.contactForm.get("phone").valueChanges.subscribe((val:any) => {
      if (val) {
          
          this.contactForm.patchValue({your_mobile:val?.number,your_country_code:val?.dialCode.substring(1)});
          console.log(this.contactForm.value);
      }
  });
  
  }
  resetForm(){
    this.contactForm.reset();
    this.contactForm.patchValue({your_country_code:'91'});  
  }
  async fetchCountries() {
    const response = await this.commonHttpService
      .post('/countries', {})
      .then((res: any) => {
        const data = res?.data ?? null;
        if (data?.status == 'success') {
          this.countries = data?.data?.docs;
        } else {
          this.toastr.error(data?.message);
        }
      })
      .catch((err: any) => {
        this.toastr.error(err);
      });
  }

  async contactformSubmit(event: any) {
    this.contactForm.markAllAsTouched();
    this.contactForm.patchValue({
      page_title: "Contact Us",
      page_url: Global.getCurrentLocation(window.location.href),
      phone: null
    });
  
    if (this.contactForm.status === "VALID") {
      this.spinner.show();
  
      try {
        const res = await this.commonHttpService.post('submit-contact-us', this.contactForm.value);
        const data = res?.data ?? null;
  
        if (data?.status === 'success') {
          Global.resetForm(this.contactForm);
          this.contactForm.patchValue({
            your_country_code: '91',
          });
          this.toastr.success(data?.message);
          this.spinner.hide();
          gtag('event', 'conversion', {'send_to': 'AW-11394164779/79XaCKT01pkZEKvQlLkq'});
  
        } else {
          if (data?.status === 'val_error') {
            this.toastr.error(Global.getValidationMessage(data?.errors ?? []));
          } else {
            this.toastr.error(data?.message);
          }
          this.spinner.hide();
        }
      } catch (err: any) {
        const errorMessage = typeof err === 'string' ? err : 'An error occurred.';
        this.toastr.error(errorMessage);
        this.spinner.hide();
      }
    }
  }
    

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.UnitedArabEmirates, CountryISO.Canada];
	}
  public findInvalidControls() {
    const invalid = [];
    const controls = this.contactForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}
}
