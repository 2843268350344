import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHttpService } from 'src/app/services/common-http.service';
import * as Global from 'src/app/global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Global = Global;
  settingsDetails: any = {};

  constructor(
    private commonHttpService: CommonHttpService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.fetchGlobalSettings();
  }

  fetchGlobalSettings(){
    return new Promise((resolve) => {
      this.commonHttpService.post('global-settings/list', {}).then((res: any) => {
        const data = res?.data ?? null;
        if (data?.status == 'success') {
          this.settingsDetails = data?.data?.global_settings;
          resolve(true);
        } else {
          this.toastr.error(data?.message);
          resolve(false);
        }
      }).catch((err: any) => {
        this.toastr.error(err);
        resolve(false);
      })
    })
  }
}
