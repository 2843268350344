export const ModulesList = [{
  label: 'Home',
  parent:true,
  link:'/',
  children: [
  ]
}, 
{
  label: 'QuickBooks',
  link:'/quickbooks',
  parent:false,
  children: [
    {
      label: 'Quickbooks pro',
      link:'/quickbooks-pro',
    } , 
    
    {
      label: 'Quickbooks premier',
      link:'/quickbooks-premier',
    } ,

    {
      label: 'Quickbooks enterprise',
      link:'/quickbooks-enterprise',
    } ,
    {
      label: 'Quickbooks pro us',
      link:'/quickbooks-pro-us',
    } ,
    {
      label: 'Quickbooks premier us',
      link:'/quickbooks-premier-us',
    } ,
    {
      label: 'Quickbooks enterprise us',
      link:'/quickbooks-enterprise-us',
    } ,
    {
      label: 'Quickbooks pro uk',
      link:'/quickbooks-pro-uk',
    } ,
    {
      label: 'Quickbooks premier uk',
      link:'/quickbooks-premier-uk',
    } ,
    {
      label: 'Quickbooks canadian',
      link:'/quickbooks-canadian',
    } ,
    {
      label: 'Quickbooks online',
      link:'/quickbooks-online',
    } ,
    {
      label: 'Quickbooks hosting',
      link:'/quickbooks-hosting',
    } ,
    {
      label: 'Quickbooks pos',
      link:'/quickbooks-pos',
    } ,
    {
      label: 'Quickbooks for mac',
      link:'/quickbooks-for-mac',
    } ,

  ]
}, 
{
  label: 'MiClient',
  parent:true,
  children: [],
  link:'/miclient',
},
{
  label: 'Corporate Tax',
  parent:false,
  link:'/corporate-tax',
  children: [
  {label:'Corporation Tax Introduction',link:'/corporation-tax-introduction'},

  {label:'Foreign Tax And Double Taxation',link:'/foreign-tax-and-double-taxation'},

  {label:'Group Relief',link:'/group-relief'},

  {label:'Transfer Pricing',link:'/transfer-pricing'},

  {label:'How Small Businesses Can Prepare',link:'/how-small-businesses-can-prepare'},
  
  {label:'What It Means For Small Business',link:'/what-it-means-for-small-business'}
  ]
},
{
  label: 'Quickbooks Add-on',
  parent:false,
  link:'/quickbooks-add-on',
  children: [
    {label:'QuickBooks Voucher Print',link:'/quickbooks-voucher-print'},
    {label:'Van Sales',link:'/van-sales'},
    {label:'QuickBooks Practices',link:'/quickbooks-practices'},
    {label:'QuickBooks E-Invoicing Software',link:'/quickbooks-e-invoicing-software'}
  ]
},
{
  label: 'Blog',
  parent:true,
  children:[],
  link:'/blogs',
},

{
  label: 'More',
  parent:false,
  link:null,
  children: [
  {label:'Business Email Hosting',link:'/email-hosting',children:[
  {label:'G Suite',link:'/g-suite'},
  {label:'Microsoft Office 365',link:'/microsoft-office365'}
]},
  {label:'Sage 50',link:'/sage-50',children:[
    {label:'Sage 50 Us',link:'/sage-50-us'},
    {label:'Sage 50 Uk',link:'/sage-50-uk'},
    {label:'Sage 300 ERP',link:'/sage-300-erp'}]},
  
  {label:'IT AMC',link:'/it-amc'},

  {label:'Keyprofit',link:'/keyprofit'},

  {label:'Tally Erp 9',link:'/tally-erp-9'},

  {label:'Xero Accounting Software',link:'/xero'},

  {label:'Cyber security',link:'/cyber-security'},

  {label:'WEBSITE DEVELOPMENT & SEO',link:'/website-development-seo'},

  {label:'Contact us',link:'/contact'},

  {label:'About',link:'/about'},

  {label:'Careers',link:'/careers'},

  {label:'Vat Calculator',link:'/vat-calculator'},

  {label:'INFOTREE STORE',link:'/infotreestore'}  

  ]
},

];
